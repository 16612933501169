import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;
const { VUE_APP_SPONSOR } = process.env;

export default class ShieldService {
  static async getPlans() {
    try {
      var category = "sf-hogar";
      var type = "home";

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/hogar?sponsor=${VUE_APP_SPONSOR}&category=${category}&type=${type}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(userData) {
    //Asignar variables para Model Person
    var person = userData.ClientData;
    person.commune = userData.ClientData.commune.id;
    person.region = userData.ClientData.region.id;

    //Asignar variables para Model Home
    var home = userData.PropetyData;
    home.property.commune = userData.PropetyData.property.commune.id;
    home.property.region = userData.PropetyData.property.region.id;

    //Recibir Id de Plan
    var planId = userData.PaymentData.selectedCoverType.planId;

    //Recibir variable para validity
    var validity = userData.PropetyData.validity;

    const jsonData = { person, home, planId, validity };
    const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/hogar`, jsonData);
    console.log(shieldRes.data.data.link);
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }
}
