<template>
    <section class="section_5">
        <div class="heading">
            <h2>Lo que dice nuestro cliente de confianza </h2>
            <p>
                El desarrollo comunitario a menudo está vinculado con el trabajo comunitario 
                o la planificación comunitaria, y puede involucrar a las partes interesadas, fundaciones.
            </p>
        </div>
        <div class="carousel">
            
            <div v-for="(comment_sect, index) in comments" :key="index" class="comments" v-show="index==step">
                <div class="comment" v-for="(comment, index) in comment_sect" :key="index">
                    <div v-if="comment.stars">
                        <img v-for="star in comment.stars" :key="star" :src="Star" />
                    </div>
                    <p>
                        {{comment.body}} 
                    </p>
                    <div>
                        <b> {{comment.name}} </b>
                    </div>
                    <div v-if="comment.nameTitle">
                        <p>{{comment.nameTitle}}</p>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button @click="stepHandler(-1)">
                    <Arrow/>
                </button>
                <button @click="stepHandler(1)">
                    <Arrow/>
                </button>
            </div>
        </div>

        <div class="carrousel_mov">
            <div v-for="(comment, index) in comments_mov" :key="index" class="comments" v-show="index==step_mov">
                <div class="comment">
                    <div v-if="comment.stars">
                        <img v-for="star in comment.stars" :key="star" :src="Star" />
                    </div>
                    
                    <p>
                        {{comment.body}} 
                    </p>

                    <div>
                        <b> {{comment.name}} </b>
                    </div>

                    <div v-if="comment.nameTitle">
                        <p>{{comment.nameTitle}}</p>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button @click="stepMovHandler(-1)">
                    <Arrow/>
                </button>
                <button @click="stepMovHandler(1)">
                    <Arrow/>
                </button>
            </div>
        </div>
        <span style="display: none;"></span>
    </section>
</template>

<script>
import Arrow from './sliders/Arrow.vue';
import Star from '@/assets/home/star.svg';

const arr = [
    {
        body: '“I searched far and wide and researched all the other options before making my decision.',
        name: 'Mansur1',
        stars: 5
    },
    {
        body: '“I could not be more thrilled that I ended up deciding on Circle to become the home of the Lightbulb Moment community.',
        name: 'John Marko1',
        stars: 4
    },
    {
        body: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.",
        name: 'Sabo Masties1',
        stars: 3
    },
    {
        body: '“I searched far and wide and researched all the other options before making my decision.',
        name: 'Mansur2',
        nameTitle: 'CEO MansurTech'
    },
    {
        body: '“I could not be more thrilled that I ended up deciding on Circle to become the home of the Lightbulb Moment community.',
        name: 'John Marko2',
        nameTitle: "Founer of Marko's"

    },
    {
        body: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.",
        name: 'Sabo Masties2',
        nameTitle: 'CTO Sabmas'
    },
    {
        body: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.",
        name: 'Sabo Masties3',
        nameTitle: 'CEO MansurTech',
        stars: 4
    },
    {
        body: '“I searched far and wide and researched all the other options before making my decision.',
        name: 'Mansur3',
        nameTitle: "Founer of Marko's",
        stars: 5
    },
    {
        body: '“I could not be more thrilled that I ended up deciding on Circle to become the home of the Lightbulb Moment community.',
        name: 'John Marko3',
        nameTitle: 'CTO Sabmas',
        stars: 4
    },
    {
        body: '“I could not be more thrilled that I ended up deciding on Circle to become the home of the Lightbulb Moment community.',
        name: 'John Marko4'
    }
]

let helper = []

export default {
    name: 'Section5',
    data: () => ({
      step: 0,
      step_mov: 0,
      comments: [],
      comments_mov: [],
      Star
    }),
    created: function(){
        this.commentsHandler();
        this.commentsMovHandler();
    },
    methods: {
        stepHandler: function(number){
            if(number>0 && this.step == this.comments.length-1){
                this.step = 0;
            } else if(number<0 && this.step == 0) {
                this.step = this.comments.length-1;
            }else{
                this.step += number;
            }
        },
        stepMovHandler: function(number){
            if(number>0 && this.step_mov == this.comments_mov.length-1){
                this.step_mov = 0;
            } else if(number<0 && this.step_mov == 0) {
                this.step_mov = this.comments_mov.length-1;
            }else{
                this.step_mov += number;
            }
        },
        commentsMovHandler: function(){
            this.comments_mov = arr;

        },
        commentsHandler: function(){
            for (let i in arr){
                helper.push(arr[i]);

                if (helper.length == 3){
                    this.comments.push(helper);
                    helper = [];
                    
                } else if (Number(i)+1 == arr.length){
                    this.comments.push(helper);
                    helper = [];
                    
                }
                
            }
            
        }
    },
    components: {
        Arrow
    }
}
</script>

<style lang="scss" scoped>
.section_5{
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading{
        width: 35%;
        text-align: center;
        h2{
            width: 90%;
            font-size: 40px;
        }
        p{
            line-height: 30px;
            color: #464E71;

            opacity: 0.7;
        }
    }

    .carousel, .carrousel_mov{
        background: #FCFAF0;
        width: 100%;
        padding: 2rem 0;
        .comments{
            display: flex;
            justify-content: center;
            .comment{
                padding: 10px;
                width: 20%;
                background: #fff;
                margin: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                animation: fadeIn 0.8s;
            }
        }
        .buttons{
            margin: auto;
            width: 100px;
            height: 50px;
            display: flex;
            padding: 5px;
            background: #fff;
            button{
                width: 50%;
                &:first-child{
                    svg{
                        transform: rotate(0.5turn);
                    }
                }
                svg{
                    fill:#464e7134;
                }

                &:hover{
                    background:#F1CF46;
                    box-shadow: 0px 8px 15px rgba(72, 72, 138, 0.08);
                    svg{
                        fill: #464E71;
                    }
                }
            }
        }
    }

    .carrousel_mov{
        display: none;
    }
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .section_5{
        .carousel{
            display: none;
        }

        .heading{
            width: 60%;
        }
        .carrousel_mov{
            display: unset;
            .comments{
                .comment{
                    width: 40%;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .section_5{
        .heading{
            width: 90%;
        }
        .carrousel_mov{
            .comments{
                .comment{
                    width: 85%;
                }
            }
        }
    }
}
</style>