<template>
    <footer class="footer">
        <section>
            <div class="footer-contact">
                <img :src="Logo" alt="Logo"/>
                <div>
                    <img :src="PhoneIcon" alt="phone icon">
                    <a href="tel:800 391 300">800 391 300</a>
                </div>
                <div>
                    <img :src="MailIcon" alt="">
                    <a href="mailto:info@megasitegmail.com">info@megasitegmail.com</a>
                </div>
                <div>
                    <img :src="LocationIcon" alt="location icon">
                    4967 Sardis Sta, Victoria 8007, <br>
                    Montreal.
                </div>
                <div class="footer-contact-rrss">
                    <img :src="FB" alt="">
                    <img :src="IG" alt="">
                    <img :src="TW" alt="">
                    <img :src="YB" alt="">
                </div>
            </div>
            <div>
                <h3>SEGURO FÁCIL</h3>
                <div>
                    <a href="https://www.southbridgeseguros.cl/#somos">Quiénes somos</a>
                    <a href="https://www.southbridgeseguros.cl/">Southbridge Seguros</a>
                    <a href="https://www.segurofacil.cl/nuestro-blog/">Nuestro Blog</a>
                </div>
            </div>
            <div>
                <h3>¿QUÉ NECESITAS?</h3>
                <div>
                    <a href="https://www.segurofacil.cl/denuncia-siniestro/">Denunciar Siniestro</a>
                    <a href="/#">Contáctanos</a>
                </div>
            </div>
            <div>
                <h3>LINKS DE INTERÉS </h3>
                <div>
                    <a href="https://www.cmfchile.cl/portal/principal/613/w3-channel.html">CMF</a>
                    <a href="https://www.segurofacil.cl/#">Términos y condiciones</a>
                    <a href="https://www.ddachile.cl/Inicio.aspx?M=0F4F1745527C84993BCD7EDC91EA748E5CB53DBA">Términos y condiciones</a>
                </div>
            </div>
        </section>
        <section>
            
            <p><span>PoweredBy <a style="text-decoration: none; color: #000;" href="https://embedx.io/" target="_blank">EmbedX </a></span>.  Hecho con  <img :src="Heart" alt="ícono de corazón"> por nuestro equipo.</p>
        </section>
    </footer>


</template>

<script>

import Logo from '@/assets/SeguroFacil.svg';

import Heart from '@/assets/home/heart_icon.svg';

import PhoneIcon from '@/assets/home/phone_icon.svg';
import LocationIcon from '@/assets/home/location_icon.svg';
import MailIcon from '@/assets/home/mail_icon.svg';

import YB from '@/assets/home/yb_icon.svg';
import IG from '@/assets/home/ig_icon.svg';
import TW from '@/assets/home/tw_icon.svg';
import FB from '@/assets/home/fb_icon.svg';

export default ({
    name: 'Footer',
    data: ()=>({
        Logo,

        Heart,

        PhoneIcon,
        LocationIcon,
        MailIcon,

        YB,
        IG,
        TW,
        FB
    })
})
</script>

<style lang="scss">
.footer{
    section{
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0.7rem;
            div{
                padding: 0.5rem 0;
                display: flex;
                flex-direction: column;
                color: #464E71;
                a{
                    text-decoration: none;
                    padding-bottom: 1rem;
                    color: #464E71;
                }
            }
        }
        .footer-contact{
            img{
                width: 60%;
            }
            div{
                display: flex;
                flex-direction: row;
                a{
                    padding-bottom: 0;
                }
                img{
                    width: 8%;
                    padding-right: 10px;
                }
            }
            .footer-contact-rrss{
                img{
                    width: 20%
                }
            }
        }
        &:last-child{
            background: #F1CF46;
            padding: 10px;
            p{
                display: flex;
                margin: auto;
                color: #000;
                img{
                    margin: 3px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footer{
        section{
            flex-direction: column-reverse;
            div{
                width: 60%;
                margin: 1rem auto;
            }
        
        }
    }
}
@media screen and (max-width: 425px) {
    .footer{
        section{
            div{
                width: unset;
            }
            .footer-contact{
                div{
                    width: 100%;
                    img{
                        width: 10%;
                    }
                }
            }
        }&:last-child{
            p{
                flex-direction: column;
                align-items: center;
                img{
                    width: 10%;
                }
            }
        }
    }
}
</style>
