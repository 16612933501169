<template>
  <div>
    <div class="avatar"><img class="avatar-image" :src="Avatar" /></div>
    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">

       <v-stepper-step :complete="e1 > 1" step="1"> Planes</v-stepper-step>
        <v-divider></v-divider>
        
        <v-stepper-step :complete="e1 >= 3" step="2"> Datos Personales </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 >= 4" step="3"> Propiedad </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4"> Confirmación </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-card class="mb-12" min-height="200px" elevation="0">

          <!--Planes DONE2-->
          <v-stepper-content step="1">

            <div class="step-data">
              <div v-if="loading" class="text-center">
                  <v-progress-circular
                    :size="100"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <br><br>{{cont}}
              </div>
              <div v-else>
                <h1>“Tienes 3 opciones de plan”</h1>
              <v-form ref="form" v-model="planForm" lazy-validation style="" class="plan-section">
                <Plan  
                  v-for="plan in plans"
                    :key="plan.id"
                    :planData="plan"
                    @next-step="selectPlan(plan)"
                />
               
              </v-form>
              </div>
            </div>
          </v-stepper-content>

          <!--ClientData DONE2-->
          <v-stepper-content step="2">
            <v-stepper v-model="e1_2" elevation="0">

              <!--Info de Contacto DONE2-->
              <v-stepper-content step="1">
                
                <div v-if="loading" class="text-center">
                  <v-progress-circular
                    :size="100"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>

                <div class="step-data">
                  <div class="back-container">
                    <v-btn class="back-button" text @click="e1--">
                      <v-icon left> mdi-chevron-left </v-icon> Atrás
                    </v-btn>
                  </div>
                  <h1>Cuéntanos de ti!</h1>
                  <v-form
                    ref="form_1"
                    v-model="valid"
                    lazy-validation
                    style="max-width: 600px;"
                  >
                    <v-text-field
                      v-model="userData.ClientData.email"
                      :rules="emailRules"
                      label="Correo electrónico"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>

                    <v-text-field
                      v-model="userData.ClientData.phoneNumber"
                      :rules="phoneRules"
                      :counter="9"
                      prefix="+56"
                      label="Telefono"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>

                    <v-text-field
                      v-model.lazy="newRut"
                      :rules="rutRules"
                      label="RUT"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                      @input="changeRut"
                    ></v-text-field>

                    <v-text-field
                      v-model="userData.ClientData.serialNumber"
                      :rules="serialNumberRules"
                      label="Nº de Documento/Serie carnet de identidad"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>
                    <v-text-field
                      v-model="userData.ClientData.name"
                      label="Nombre"
                      :rules="requiredRules"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>
                    
                    <v-text-field
                      v-model="userData.ClientData.surname"
                      label="Apellido"
                      :rules="requiredRules"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>


                    <div class="button-container">
                      <v-btn color="#E8C665" elevation="0" @click="
                        validate('form_1') ? e1_2++:e1_2=e1_2;
                      ">
                        Continuar
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </v-stepper-content>

              <!--Direccion contratante DONE-->
              <v-stepper-content step="2">
                <div class="step-data">
                  <div class="back-container">
                    <v-btn class="back-button" text @click="e1_2--">
                      <v-icon left> mdi-chevron-left </v-icon> Atrás
                    </v-btn>
                  </div>
                  <h1>“Dónde vives?”</h1>
                  <v-form
                    ref="form_4"
                    v-model="addressForm"
                    lazy-validation
                    style="max-width: 600px;"
                  >
                    <v-text-field
                      v-model="userData.ClientData.address"
                      :rules="addressRules"
                      label="Direccion"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>
                    
                    <v-select
                      :items="regions"
                      v-model="userData.ClientData.region"
                      :rules="regionRules"
                      :loading="loadingRegions"
                      v-on:change="getCommunes()"
                      item-text="name"
                      label="Region"
                      required
                      filled
                      flat
                      solo
                      return-object
                      background-color="#eef3f8"
                    ></v-select>
                    <v-select
                      :items="communes"
                      v-model="userData.ClientData.commune"
                      :rules="communeRules"
                      :loading="loadingCommunes"
                      :disabled="loadingCommunes || userData.region === null"
                      item-text="name"
                      label="Comuna"
                      required
                      filled
                      flat
                      solo
                      return-object
                      background-color="#eef3f8"
                    ></v-select>

                    <div class="button-container">
                      <v-btn color="#E8C665" elevation="0" @click="
                        validate('form_4') ? e1_2++:e1_2=e1_2;
                      ">
                        Siguiente
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </v-stepper-content>

              <!--Los datos son iguales? DONE-->
              <v-stepper-content step="3">
                <div class="step-data">
                  <div class="back-container">
                    <v-btn class="back-button" text @click="()=>{e1_2--; this.hasContractor='';}">
                      <v-icon left> mdi-chevron-left </v-icon> Atrás
                    </v-btn>
                  </div>
                  <div style="max-width: 500px">
                    <h1>
                      “El Rut del propietario es el mismo que el del Contratante?”
                    </h1>
                  </div>
                  <v-form
                    ref="form_5"
                    v-model="planForm"
                    lazy-validation
                    style="max-width: 600px; "
                  >
                    <v-radio-group :rules="requiredRules" v-model="userData.hasContractor" row>
                      <v-radio value="si"
                        ><template v-slot:label>
                          <div class="forecast-selector">Si</div>
                        </template></v-radio
                      >
                      <v-radio value="no"
                        ><template v-slot:label>
                          <div class="forecast-selector">No</div>
                        </template></v-radio
                      >
                    </v-radio-group>
                    <v-form
                      ref="form3"
                      v-model="propDataForm"
                      lazy-validation
                      style="max-width: 600px; "
                      v-show="userData.hasContractor=='no'"
                    >
                      <v-text-field
                        v-model.lazy="newRutP"
                        :rules="rutRules"
                        label="RUT del propietario"
                        required
                        filled
                        flat
                        solo
                        background-color="#eef3f8"
                        @input="changeRutP"
                      ></v-text-field>

                  </v-form>
                    <div class="button-container">
                      <v-btn color="#E8C665" elevation="0" @click=" sameClientProp('form_5', 'form3')">
                        Siguiente
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </v-stepper-content>

            </v-stepper>
          </v-stepper-content>
          
          <!--PropiedadData DONE2-->
          <v-stepper-content step="3">
            <v-stepper v-model="e1_3" elevation="0">

              <!--Dirección propiedad asegurada DONE-->
              <v-stepper-content step="1">
                <div class="step-data">
                  <div class="back-container">
                    <v-btn class="back-button" text @click="e1--">
                      <v-icon left> mdi-chevron-left </v-icon> Atrás
                    </v-btn>
                  </div>
                  <h1>“Dónde queda la propiedad asegurada?”</h1>
                  <v-form
                    ref="form_6"
                    v-model="addressPropForm"
                    lazy-validation
                    style="max-width: 600px;"
                  >
                    <v-text-field
                      v-model="userData.PropetyData.property.street"
                      :rules="addressRules"
                      label="Calle"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>

                    <v-text-field
                      v-model="userData.PropetyData.property.number"
                      :rules="addressRules"
                      label="Número de la propiedad"
                      required
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>

                    <v-select
                      :items="regions"
                      v-model="userData.PropetyData.property.region"
                      :rules="regionRules"
                      :loading="loadingRegions"
                      v-on:change="getCommunesProp()"
                      item-text="name"
                      label="Region"
                      required
                      filled
                      flat
                      solo
                      return-object
                      background-color="#eef3f8"
                    ></v-select>
                    <v-select
                      :items="communes"
                      v-model="userData.PropetyData.property.commune"
                      :rules="communeRules"
                      :loading="loadingCommunes"
                      :disabled="loadingCommunes || userData.region === null"
                      item-text="name"
                      label="Comuna"
                      required
                      filled
                      flat
                      solo
                      return-object
                      background-color="#eef3f8"
                    ></v-select>

                    <div class="button-container">
                      <v-btn color="#E8C665" elevation="0" @click="
                        validate('form_6') ? e1_3++:e1_3=e1_3;
                      ">
                        Siguiente
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </v-stepper-content>

              <!--Características-->
              <v-stepper-content step="2">
                <div class="step-data">
                  <div class="back-container">
                    <v-btn class="back-button" text @click="e1_3--">
                      <v-icon left> mdi-chevron-left </v-icon> Atrás
                    </v-btn>
                  </div>
                  <div style="max-width: 600px">
                    <h1>
                      “Con cuáles de estas características cuenta la propiedad?”
                    </h1>
                  </div>
                  <v-form
                    ref="form_7"
                    v-model="planForm"
                    lazy-validation
                    style="max-width: 500px; "
                  >
                    <!-- <p class="select_label">Qué tipo de propiedad es?</p> -->
                    <v-radio-group :rules="requiredRules" label="Qué tipo de propiedad es?" v-model="userData.PropetyData.property.type" row>
                      <v-radio value="CASA"
                        ><template v-slot:label>
                          <div class="forecast-selector">Casa</div>
                        </template></v-radio
                      >
                      <v-radio value="DEPARTAMENTO"
                        ><template v-slot:label>
                          <div class="forecast-selector">Departamento</div>
                        </template></v-radio
                      >
                    </v-radio-group>

                    <v-text-field
                      v-model="userData.PropetyData.property.departament"
                      label="Número departamento/timbre"
                      :rules="userData.PropetyData.property.type == 'DEPARTAMENTO'?requiredRules:[]"
                      filled
                      flat
                      solo
                      background-color="#eef3f8"
                    ></v-text-field>

                    <!-- <p class="select_label">Qué uso se le da a la propiedas?</p> -->
                    <v-radio-group label="Qué uso se le da a la propiedas?" v-model="userData.PropetyData.property.use" row>
                      <v-radio value="COMERCIAL"
                        ><template v-slot:label>
                          <div class="forecast-selector">Comercial</div>
                        </template></v-radio
                      >
                      <v-radio value="HABITACIONAL">
                        <template v-slot:label>
                          <div class="forecast-selector">Habitacional</div>
                        </template>
                      </v-radio>

                      <v-radio value="VACACIONAL">
                        <template v-slot:label>
                          <div class="forecast-selector">Vacacional</div>
                        </template>
                      </v-radio>
                    </v-radio-group>

                    <!-- <v-radio-group label="Cuál es el tipo de material de la Casa?" v-model="userData.PropetyData.place.materialsContructions" row>
                      <v-radio value="LIGERA"
                        ><template v-slot:label>
                          <div class="forecast-selector">LIGERA</div>
                        </template></v-radio
                      >
                      <v-radio value="MIXTA">
                        <template v-slot:label>
                          <div class="forecast-selector">MIXTA</div>
                        </template>
                      </v-radio>

                      <v-radio value="SÓLIDA">
                        <template v-slot:label>
                          <div class="forecast-selector">Sólida</div>
                        </template>
                      </v-radio>
                    </v-radio-group> -->

                    <p class="select_label">Colindante o cercana a terrenos eriazos, deshabilitados y/o abandonados, cercana a cursos de agua (a menos de 500 metros), cercana a plantaciones o bosques.</p>
                    <v-radio-group v-model="userData.PropetyData.property.adjacent" row>
                      <v-radio value="Si"
                        ><template v-slot:label>
                          <div class="forecast-selector">Si</div>
                        </template></v-radio
                      >
                      <v-radio value="No">
                        <template v-slot:label>
                          <div class="forecast-selector">No</div>
                        </template>
                      </v-radio>

                    </v-radio-group>

                    <div class="button-container">
                      <v-btn color="#E8C665" elevation="0" @click="e1++">
                        Siguiente
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </v-stepper-content>

            </v-stepper>
          </v-stepper-content>

          <!--Aceptar DONE-->
          <v-stepper-content step="4">
            <div v-if="loading || loadingFinal" class="text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
              :label="cont"

            ></v-progress-circular>
            <br><br>{{cont}}
            </div>

            <div v-else-if="!loading && !loadingFinal" class="step-data" >
              <div class="step-data">
                <div class="back-container">
                  <v-btn class="back-button" text @click="e1--">
                    <v-icon left> mdi-chevron-left </v-icon> Atrás
                  </v-btn>
                </div>
                <h1>“Estás a un paso de contratar tu seguro!”</h1>
                <p style="max-width: 600px">
                  Al aceptar, doy fe de que la propiedad no se encuentra 
                  dentro de los siguientes sectores: Barrio Frankilin, 
                  Matadero Lo Valledor, Barrio Patronato y Bellavista 
                  entre las calles Pío Nono, Recoleta, Bellavista y Santos 
                  Dumont, Barrio Meiggs, Bascuñan Guerrero, Salvador San 
                  Fuentes, Exposición, Sazié, Santiago antiguo entre 21 de 
                  Mayo y Matucana Santo Domingo, San Pablo, Mercado Central, 
                  Barrio 10 de Julio, comuna de Lampa.
                </p>
                <v-form
                  ref="form_condiciones"
                  v-model="planForm"
                  lazy-validation
                  style="max-width: 600px;"
                >

                  <v-checkbox
                    v-model="userData.agree"
                    :rules="[(v) => !!v || 'Debes aceptar para continuar']"
                    required
                    style="width: unset;"
                    ><template v-slot:label>
                      <div class="agree">
                        Acepto los <a>Términos y Condiciones</a>
                      </div>
                    </template></v-checkbox
                  >

                  <div class="button-container">
                    <v-btn color="#E8C665" elevation="0" @click="getFlow()">
                      Siguiente
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </div>
          </v-stepper-content>

        </v-card>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import Avatar from "@/assets/Avatar.png";
import maleImage from "@/assets/male.svg";
import femaleImage from "@/assets/female.svg";
import otherImage from "@/assets/other.svg";
import addIcon from "@/assets/profile-add.svg";
import RutUtils from "../utils/rut.utils";
import CountryService from "../services/country.service";
import Plan from "@/components/Plan.vue";
import ShieldService from "@/services/shield.service.js";
import PlanOne from "@/assets/plan_1.svg";
import PlanTwo from "@/assets/plan_2.svg";
import PlanThree from "@/assets/plan_3.svg";

export default {
  components: {
    Plan,
  },
  data() {
    return {
      cont:"Cargando.  ",
      loading: true,
      loadingFinal: false,
      Avatar,
      addIcon,
      beneficiaries: [{ id: 1, rut: null, type: null, birthdate: null }],
      userData: {
        ClientData: {
          gender: ""
        },
        PropetyData: {
          rut: "",
          property: {
            street: ""
          },
          place: {
            sectors: "Confirmed"
          },
          validity: {
            quantity: 1,
            unit: "M"
          },
          metadata: []
        },
        PaymentData: {
          selectedCoverType: ""
        }
      },
      newRut: "",
      newRutP: "",
      genderOptions: [
        {
          id: "male",
          label: "Masculino",
          image: maleImage,
        },
        {
          id: "female",
          label: "Femenino",
          image: femaleImage,
        },
        {
          id: "other",
          label: "Otro",
          image: otherImage,
        },
      ],
      e1: 1,
      e1_2: 1,
      e1_3: 1,
      valid: false,
      planForm: true,
      nameRutForm: true,
      addressPropForm: true,
      idForm: true,
      addressForm: true,
      propDataForm: true,
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
      ],
      phoneRules: [
        (v) => !!v || "El número telefónico es requerido",
        (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
        (v) => (!!v && v.length == 9) || "Ingrese un número válido",
      ],
      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => RutUtils.validateRut(v) || "Rut no valido",
      ],
      serialNumberRules: [(v) => !!v || "El numero de serie es requerido"],
      addressRules: [(v) => !!v || "Debes ingresar tu direccion"],
      regionRules: [
        (v) => !!v || "Ingresa la region en la que vives con tu mascota",
      ],
      communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives"],
      requiredRules: [(v) => !!v || "Este campo es requerido"],
      loadingRegions: false,
      loadingCommunes: false,
      regions: [],
      communes: [],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      menu: false,
      plans: [],
      selectedPlan: null,
    };
  },
  mounted() {
    this.getPlans();
    this.getRegions();
  },
  methods: {
    test() {
      //this.e1 = this.e1;
      console.log(this.userData)
    },
    sameClientProp(form_stage, form_stage_1){
      // Rompe cuando es no y después sí

      if (this.$refs[form_stage].validate()) {
        // No son los mismos y se valida el formulario de datos de propietario
        if (this.$refs[form_stage_1].validate() && this.userData.hasContractor == 'no' ){
          this.e1 ++;

        // Son los mismos y el RUT del cliente y el propietario
        } else if (this.userData.hasContractor == 'si') {
          this.userData.PropetyData.rut = this.userData.ClientData.rut;
          this.e1++;
        } 
      }

    },
    validate(form_stage) {
      if(this.$refs[form_stage].validate()){
        //this.e1++;
        return true;
      } else {
        return false;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    changeRut(rut) {
      this.userData.ClientData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    changeRutP(rut) {
      this.userData.PropetyData.rut = RutUtils.cleanRut(rut);
      this.newRutP = RutUtils.formatRut(rut);
    },
    async getPlans(){
      try {

          var effect = this.cont;
          for (var x = 0; x < 5;x++){
            effect += " . ";
            
            setTimeout(() => {  this.cont = effect; }, 1000);
          }

          var image;
          const res = await ShieldService.getPlans(
          );
          
          res.forEach((element)=> {
            if (element.name == "Plan Cuidado y Prevencion"){
              image = PlanOne
            } else if(element.name == "Plan Explora en Casa"){
              image = PlanTwo
            } else if(element.name == "Plan Vida Fit"){
              image = PlanThree
            }
            this.plans.push({
              planId: element._id,
              name: element.name,
              title: element.type,
              description: element.coverage[0].description,
              priceCLP: element.values[0].priceCLP,
              priceUF: element.values[0].price,
              image: image,
            });

            this.cont = "Cargando.  "

          });
          this.loading = false
        
        } catch (error) {
          console.log(error)
          window.alert("Ocurrió un error al momento de traer el plan");
        }
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.ClientData.region) {
        this.loadingCommunes = true;
        this.userData.ClientData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.ClientData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    async getCommunesProp() {
      if (this.userData.PropetyData.region) {
        this.loadingCommunes = true;
        this.userData.PropetyData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.PropetyData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    selectPlan(plan) {
      this.userData.PaymentData.selectedCoverType = plan;
      this.e1 = this.e1 + 1;
    },
    addBeneficiary() {
      this.beneficiaries.push({
        id: this.beneficiaries.length + 1,
        rut: null,
        type: null,
        birthdate: null,
      });
    },
    async getFlow() {

      this.loadingFinal = true;
      this.cont = "Estamos Procesando tu Solicitud . . ."

      try {
          const res = await ShieldService.createInsurance(
            this.userData
          );
          // Redirigir a Flow
          window.location.href = res;
        } catch (error) {
          this.loadingFinal = false;
          this.loading = false;
          window.alert("Ocurrió un error. Verifica que tus datos sean correctos");
        }
      
      this.loading = false;

    },
  }
};
</script>

<style lang="scss">
.v-form{
  width: 100%;
}
/* v-input__control */
.v-input--selection-controls.v-input .v-input__control{
  align-items: center;
  .v-input__slot{
    width: unset;
  }
}

p.select_label{
  opacity: 0.9;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0;
}
.v-input--selection-controls{
  margin-top: 0;
}
.v-input--radio-group--row .v-input--radio-group__input{
  legend{
    width: 100%;
    text-align: center;
  }
}
.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 500;
    text-align: center;
    color: #464e71;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 500;
    color: #464e71;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
  }

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}

.v-btn {
  text-transform: unset !important;
  height: unset !important;
}

.v-btn__content {
  padding: 0.75rem;
}

.gender-selector {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;

  .gender-option {
    background-color: #eef3f8;
    border: 1px solid #eef3f8;
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.25;
    width: 135px;
    // height: 65px;
    cursor: pointer;
    border-radius: 12px;
    padding: 1rem;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: #d4e2ed;
      border: 1px solid #d4e2ed;
    }

    &.active {
      border: 1px solid #2c6ea9;
    }
  }
}

.v-input--radio-group__input {
  justify-content: center;
}
.plan-section{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.add-beneficiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  .add-icon {
    border: 1px solid #2c6ea9;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .add-label {
    margin-left: 1rem;
    color: #444444;
    // opacity: 0.5;
  }

  &:hover {
    opacity: 0.5;
  }
}

.b-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .b-label {
    color: #444444;
    opacity: 0.75;
  }

  .b-value {
    color: #444444;
  }
}

.avatar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    width: 100%;
    border-bottom: solid 1px #ececec;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 1;
  }
  .avatar-image {
    z-index: 3;
  }
}

@media screen and (max-width: 500px) {
  .gender-selector{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>