<template>
    <section class="section_4">
        <h2>¿Por qué con nosotros?</h2>
        <div>
            <img :src="Family2" alt="">
            <div>
                <div>
                    <img :src="Benefit1" alt="">
                    <h6>Rápido y Fácil</h6>
                    <p>Asegure su hogar con solo un par de clics.</p>
                </div>

                <div>
                    <img :src="Benefit2" alt="">
                    <h6>Conveniente y Flexible</h6>
                    <p>Seguro de reparto bajo según su presupuesto.</p>
                </div>

                <div>
                    <img :src="Benefit3" alt="">
                    <h6>Digital Frindly ¡Hasta el final!</h6>
                    <p>Sin papeleo ni trámites.</p>
                </div>

                <div>
                    <img :src="Benefit4" alt="">
                    <h6>Reporto en línea</h6>
                    <p>¡Proceso de informes simple y en línea!</p>
                </div>

                <div>
                    <img :src="Benefit5" alt="">
                    <h6>Inquilinos y millennials</h6>
                    <p>No importa si usted es un inquilino, su casa también puede ser protegida</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Family2 from '@/assets/home/family_2.png';

import Benefit1 from '@/assets/home/benefit1.svg';
import Benefit2 from '@/assets/home/benefit2.svg';
import Benefit3 from '@/assets/home/benefit3.svg';
import Benefit4 from '@/assets/home/benefit4.svg';
import Benefit5 from '@/assets/home/benefit5.svg';

export default {
    name: 'Section4',
    data:()=>({
        Family2,
        Benefit1,
        Benefit2,
        Benefit3,
        Benefit4,
        Benefit5
    })
}
</script>

<style lang="scss" scoped>
.section_4{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
    h2{
        font-size: 40px;
        margin-bottom: 2rem;
    }
    div{
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;

        img{
            width: 40%;
        }
        div{
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;

            div{
                flex-direction: column;
                width: 30%;
                margin: 10px;
                align-items: center;
                h6{
                    font-size: 18px;

                }
                text-align: center;
            }
        }
    }
}

@media (max-width: 1024px) {
    .section_4{
        div{
            flex-direction: column-reverse;
            div{
                flex-direction: row;
            }
        }
    }
}

@media (max-width: 768px) {
    .section_4{
        div{
           
            div{
                div{
                    margin: auto;
                    img{
                        display: unset;
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .section_4{
        h2{
            text-align: center;
        }
        div{
            img{
                display: none;
            }
            div{
                flex-direction: column;
                align-items: center;
                div{
                    width: 50%;
                    img{
                        display: unset;
                        width: 40%;
                    }
                }
            }
        }
    }
}
</style>