<template>
  <section class="section_1">

    <div>
      <p style="color: #2C6EA9;">Seguro de Inquilino</p>
      <h1>Su tranquilidad es la paz de sus cercanos</h1>
      <router-link style="text-decoration: none;" :to="{ path: '/cotizar' }">
        <v-btn color="#E8C665" elevation="0">
          Cotizar!
        </v-btn>
      </router-link>
      <p>¡Experiencia digital sin complicaciones!</p>
    </div>

    <img :src="Family" alt="">
  </section>
</template>

<script>
import Family from '@/assets/family.svg';

export default {
  name: 'Section1',
  data: ()=>({
    Family
  })
  
}
</script>

<style lang="scss" scoped>
.section_1{
  display: flex;
  justify-content: flex-end;
  padding: 2rem 4rem 8rem 0;
  background-image: url('../assets/home/sect_1bkgrd.svg');
  background-size: cover;

  div{
    width: 45%;
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 82px;
    }
    a{
      padding: 1rem 0;
    }
    button{
      margin-top: 1rem;
    }
    p:last-child{
      margin-top: 1rem;
    }
  }

  img{
    width: 40%;
  }
}

@media (max-width: 1024px) {
  .section_1{
    padding: 2rem 0rem 8rem 0;
    align-items: center;
    div{
      width: 50%;
      h2{
        font-size: 50px;
      }
    }

  }
}

@media (max-width: 768px) {
  .section_1{
    justify-content: center;
    div{
      width: 70%;
      h1{
        font-size: 45px;
        line-height: 60px;
      }
    }
    img{
      display: none;
      
    }
  }
}

@media (max-width: 425px) {
  .section_1{
    div{
      text-align: center;
      align-items: center;
      h1{
        font-size: 40px;
      }
    }
  }
}
</style>
