<template>
    <section class="section_2">
        <h2>Cobertura y Asistencia</h2>

        <div class="ben">
            <div>
                <img :src="Icon1" alt="">
                <h6>Incendio de contenido??</h6>
            </div>
            <div>
                <img :src="Icon2" alt="">
                <h6>Robo de contenido??</h6>
            </div>
            <div>
                <img :src="Icon3" alt="">
                <h6>Técnico Electricista</h6>
            </div>
            <div>
                <img :src="Icon4" alt="">
                <h6>Plomería</h6>
            </div>
        </div>
        <div class="ben e_stuff">
            <div>
                <img :src="Icon5" alt="">
                <h6>E-Chef</h6>
                <p>Asistencia Remota</p>
            </div>
            <div>
                <img :src="Icon6" alt="">
                <h6>E-Teacher</h6>
                <p>Asistencia Remota</p>
            </div>
            <div>
                <img :src="Icon7" alt="">
                <h6>E-Nutritionist</h6>
                <p>Asistencia Remota</p>
            </div>
        </div>
    </section>
</template>

<script>
import Icon1 from '@/assets/home/icon1.svg';
import Icon2 from '@/assets/home/icon2.svg';
import Icon3 from '@/assets/home/icon3.svg';
import Icon4 from '@/assets/home/icon4.svg';
import Icon5 from '@/assets/home/icon5.svg';
import Icon6 from '@/assets/home/icon6.svg';
import Icon7 from '@/assets/home/icon7.svg';

export default {
    name: 'Section2',
    data: ()=>({
        Icon1,
        Icon2,
        Icon3,
        Icon4,
        Icon5,
        Icon6,
        Icon7
    })
}
</script>

<style lang="scss" scoped>
.section_2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;

    h2{
        margin-bottom: 4rem;
        font-size: 40px;
    }
    .ben{
        display: flex;
        flex-direction: row;

        div{
            margin: 0.5rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 22%;
            h6{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 36px;
                text-align: center;
            }
        }

        &:last-child{
            div{
                width: 35%;
            }
        }
    }
    
}

@media (max-width: 545px) {
    .section_2{
        h2{
            text-align: center;
            width: 100%;
        }

        .ben{
            flex-wrap: wrap;
            justify-content: center;
            div{
                width: 100px;
                p{
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .section_2{
        h2{
            margin: 0;
            margin-bottom: 1rem;
        }
        .ben{
            flex-direction: column;
            align-items: center;
            width: 100%;
            div{
                width: 70%;
            }
        }
    }
}


</style>

