<template>
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 4.99984L14.9713 0.833171L14.9713 4.1665L5.96244e-08 4.1665L3.97496e-08 5.83317L14.9713 5.83317L14.9713 9.1665L20 4.99984Z" 
        />
    </svg>
</template>
<script>
export default {
    name: 'Arrow'
}
</script>