<template>
    <section class="section_3">
        <h2>"No necesito seguro de hogar..."</h2>
        <p>Si lo piensa, lea las siguientes razones por las que debería tener un seguro.</p>

        <div class="args">
            <div>
                <div>
                    <img :src="Icon8" alt="">
                    <p>En Chile cada año hay 53 mil casos de cáncer y muchos de ellos se detectan en estado avanzado.</p>
                </div>

                <div>
                    <img :src="Icon9" alt="">
                    <p>Hay más de 100 tipos de cáncer y cualquier parte de su cuerpo puede verse afectada.</p>
                </div>
            </div>
            <div>
                <div>
                    <img :src="Icon10" alt="">
                    <p>El costo del tratamiento del cáncer de mama puede rondar los 12 millones de pesos.</p>
                </div>

                <!-- <div>
                    <img :src="Icon11" alt="">
                    <p>The cost of breast cancer treatment can be around $12 million pesos.</p>
                </div> -->
            </div>
            
        </div>
        <router-link style="text-decoration: none;" :to="{ path: '/cotizar' }">
            <v-btn color="#E8C665" elevation="0">
                Cotizar!
            </v-btn>
        </router-link>
    </section>
</template>

<script>
import Icon8 from '@/assets/home/icon8.svg';
import Icon9 from '@/assets/home/icon9.svg';
import Icon10 from '@/assets/home/icon10.svg';
import Icon11 from '@/assets/home/icon11.svg';

export default {
    name: 'Section3',
    data: ()=>({
        Icon8,
        Icon9,
        Icon10,
        Icon11
    })
}
</script>

<style lang="scss" scoped>
.section_3{
    background: #2c6fa93f;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;

    h2{
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 72px;
        text-align: center;
        width: 30%;
    }
    p{
        margin-bottom: 3rem;
    }
    .args{
        div{
            display: flex;
            justify-content: center;
            &:first-child{
                margin-bottom: 1rem;
            }
            div{
                background: #fff;
                width: 35%;
                border-radius: 10px;
                padding: 15px;
                margin-bottom: 0;
                display: flex;
                align-items: center;

                &:first-child{
                    margin-bottom: 0;
                    margin-right: 1rem;
                }

                p{
                    line-height: 30px;
                    margin-bottom: 0;
                }
                img{
                    width: 15%;
                    margin-right: 20px;
                }
            }
        }
        
    }
    button{
        margin-top: 1rem;
    }
}

@media (max-width: 1024px) {
    .section_3{
        h2{
            width: 45%;
        }
    }
}

@media (max-width: 768px) {
    .section_3{
        h2{
            width: 60%;
        }
        p{
            text-align: center;
            width: 90%;
        }
        .args{
            div{
                &:first-child{
                    margin-bottom: 0rem;
                    flex-direction: column;
                    align-items: center;
                }

                div{
                    margin: 0;
                    margin-top: 1rem;
                    flex-direction: column;
                    width: 50%;
                    &:first-child{
                        margin-bottom: 0;
                        margin-right: 0rem;
                    }

                   
                }
                
            }
        }
    }
}

@media (max-width: 425px) {
    .section_3{
        h2{
            width: 80%;
        }
        .args{
            div{
                div{
                    width: 80%;
                    img{
                        width: 30%;
                    }
                }
            }
        }
    }
}
</style>