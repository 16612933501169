<template>
  <v-app>
    <v-app-bar app color="#FFFFFF" light elevation="0" height="75">
      <router-link :to="{ path: '/' }">
        <div class="d-flex align-center">
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="75"
            :src="SeguroFacil"
            max-width="150"
            style="margin-left: 2rem"
          />
        </div>
      </router-link>

      <v-spacer></v-spacer>

      <router-link style="text-decoration: none" :to="{ path: '/' }">
        <v-btn text>
          <span class="mr-2">MIS SEGUROS</span>
        </v-btn>
      </router-link>

      <router-link
        style="text-decoration: none; color: red"
        :to="{ path: '/' }"
      >
        <v-btn text>
          <span class="mr-2">INICIAR SESIÓN</span>
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <Footer/>
  </v-app>
</template>

<script>
import SeguroFacil from "@/assets/SeguroFacil.svg";
import Footer from '@/components/Footer.vue';

export default {
  name: "App",

  data: () => ({
    SeguroFacil,
  }),
  components: {
    Footer
  }
};
</script>