<template>

  <section>
    <v-alert v-if="success === true" dense text type="success">
      ¡Has contratado tu seguro con éxito! Revisa tu <strong>correo electrónico</strong> para obtener toda la información.
    </v-alert>
    <v-alert v-if="success === false" dense text type="error">
      Ha ocurrido un error al contratar tu seguro. Intenta nuevamente.
    </v-alert>
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
  </section>
</template>

<script>
  import Section1 from '../components/Section1.vue';
  import Section2 from '../components/Section2.vue';
  import Section3 from '../components/Section3.vue';
  import Section4 from '../components/Section4.vue';
  import Section5 from '../components/Section5.vue';
  

  export default {
    name: 'Home',

    components: {
      Section1,
      Section2,
      Section3,
      Section4,
      Section5
    },
    data() {
    return {
      success: null,
    };
    },
    mounted() {
    const query = this.$route.query;
    if (query) {
        if (query.success === "true") {
          this.success = true;
        } else if (query.success === "false") {
          this.success = false;
        }
      }
    },
    methods: {
      test: function(){
        console.log('gg wp')
      }
    },
    
  }
</script>

<style lang="scss">
@import url('../styles/main.scss');

h1,h2,h6{
  color: #343B57;
}
.v-alert--text {
  margin-bottom: 0 !important; 
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}
</style>